import React, { useContext, useState } from "react";
import { graphql } from "gatsby";
import { typeStr, asStr } from "../lib/performanceStr";
import { compact, padStart, flatten, uniq, pick } from "lodash";
import TrackContext from "../lib/trackContext";
import PerformanceItemPage from "../components/PerformanceShowPage";
import { PerformanceProps, PerformanceSchema, TrackProps } from "../lib/";

const formatSetlistItem = (
  setlistItem,
  selectedSource,
  trackContext
): TrackProps => {
  const currentID = trackContext.data.id;
  const recordingID =
    (setlistItem.recordings &&
      setlistItem.recordings.find(
        (recording) => recording.source === selectedSource
      ).id) ||
    null;
  return {
    key: setlistItem.song.slug + recordingID,
    recordingID,
    hasRecording: !!recordingID,
    title: setlistItem.song.title,
    trackNumber: `${padStart(setlistItem.trackNumber, 2, "0")}.`,
    onPlayClick: () => {
      if (recordingID) {
        trackContext.setTrackContext({
          id: recordingID,
          collection: "performances",
        });
      }
    },
    setlistItem: setlistItem,
    songSlug: setlistItem.song.slug,
    isPlaying: recordingID && currentID && recordingID === currentID,
  };
};

const formatQueryData = ({
  performance,
}: {
  performance: PerformanceSchema;
}): PerformanceProps => {
  const sources = compact(
    uniq(
      flatten(
        (performance.setlistItems || []).map((setlistItem) => {
          if (setlistItem.recordings) {
            return (setlistItem.recordings || []).map(
              (recording) => recording.source
            );
          } else {
            return [];
          }
        })
      )
    )
  ) as string[];
  return {
    ...pick(performance, "date", "startTime", "endTime", "setlistItems"),
    type: typeStr(performance.type),
    venueAddress: `${performance.location.address.locality}, ${performance.location.address.region}`,
    venueName: performance.location.name,
    as: asStr(performance.as),
    hasSetlist: performance.setlistItems.length > 0,
    hasRecordings: sources.length > 0,
    sources,
  };
};

export default ({ data, location }) => {
  const trackContext = useContext(TrackContext);
  const queryData = formatQueryData(data);
  const [selectedSource, setSource] = useState(queryData.sources[0]);
  return (
    <PerformanceItemPage
      {...queryData}
      onSourceChange={(s) => setSource(s)}
      selectedSource={selectedSource}
      location={location}
      tracks={queryData.setlistItems.map((item) =>
        formatSetlistItem(item, selectedSource, trackContext)
      )}
    />
  );
};

export const query = graphql`
  query($slug: String!) {
    performance: performances(slug: { eq: $slug }) {
      id
      type
      as
      startTime
      endTime
      setlistItems {
        trackNumber
        song {
          title
          slug
        }
        recordings {
          performanceKey
          source
          id
        }
      }
      date
      filename
      slug
      location {
        name
        address {
          country
          locality
          region
        }
      }
    }
  }
`;

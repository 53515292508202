import React from "react";
import { PerformanceShowPageProps } from "../lib/";
import PageLayout from "./PageLayout";

export default (props: PerformanceShowPageProps) => {
  const {
    as,
    tracks,
    date,
    endTime,
    hasSetlist,
    startTime,
    type,
    venueAddress,
    venueName,
    sources,
    selectedSource,
    onSourceChange,
    hasRecordings,
  } = props;
  return (
    <PageLayout>
      <article>
        <section>
          <h1>
            {as}: {type}
          </h1>
          <h2>
            {date}: {venueName} ({venueAddress})
          </h2>
          <h3>{[startTime, endTime].filter((e) => e).join(" - ")}</h3>
        </section>
        <hr />
        {hasSetlist && (
          <section>
            <h3>Setlist</h3>
            {hasRecordings && sources.length > 1 && (
              <h4>
                Recording Source:&nbsp;
                <select
                  value={selectedSource}
                  onChange={(e) => onSourceChange(e.target.value)}
                >
                  {sources.map((source) => (
                    <option value={source} key={source}>
                      {source}
                    </option>
                  ))}
                </select>
              </h4>
            )}
            {hasRecordings && sources.length === 1 && (
              <h4>Recording Source: {sources[0]}</h4>
            )}
            <table>
              <tbody>
                {tracks.map(
                  ({
                    recordingID,
                    title,
                    trackNumber,
                    songSlug,
                    isPlaying,
                    onPlayClick,
                  }) => (
                    <tr key={`${trackNumber}-${title}`}>
                      <td>
                        {recordingID && (
                          <a href="#" onClick={onPlayClick}>
                            [Play]
                          </a>
                        )}
                      </td>
                      <td>{trackNumber}</td>
                      <td>{title}</td>
                      <td>
                        <a href={songSlug}>[Song Info]</a>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </section>
        )}
      </article>
    </PageLayout>
  );
};
